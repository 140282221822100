@import '../../styles/marketplace.css';

p {
  margin: 0;
}

.spinnerContainer {
  width: 100%;
  height: 40vh;
  display: flex;
  align-items: center;
  justify-content: center;
}

.wrapperMain {
  max-width: 1260px;
  margin: 0 auto;
  padding: 32px;
}

.title {
  font-size: 32px;
  line-height: 42px;
  margin-top: 0;
  margin-bottom: 24px;
  color: var(--marketplaceColor);

  @media (--viewportMedium) {
    margin-bottom: 40px;
    font-size: 48px;
    line-height: 64px;
  }
}

.walletContainer {
}

.balanceContainer {
  gap: 18px;
  @media (--viewportMedium) {
    display: flex;
    gap: 20px;
  }

  /* width: 100%; */
}

.bottomInfo {
  width: 100%;

  justify-content: space-between;
  align-items: end;
  margin-top: 16px;

  @media (--viewportMedium) {
    display: flex;
    margin-top: 60px;
  }
}

.payoutButton {
  padding: 8px 16px;
  background-color: #4f56d3;
  border-radius: 12px;
  color: white;
  border: none;
  font-size: 18px;
  line-height: 24px;
  width: 100%;
  margin-top: 24px;
  cursor: pointer;
  font-weight: 600;

  @media (--viewportMedium) {
    width: unset;
    margin-left: 10px;
  }
}

.leftBalance,
.rightBalance {
  flex: 1;
  display: flex;
  /* width: 348px; */
  /* height: 320px; */
  padding: 20px;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  flex-shrink: 0;
  border-radius: 24px;
  background: #e7ebff;

  @media (--viewportMedium) {
    padding: 28px;
  }
}

.rightBalance {
  background: #f3f3f3;
  margin-top: 18px;

  @media (--viewportMedium) {
    margin-top: 0px;
  }
}

.balanceBar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 40px;
}
.iconWrapper {
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: white;
  border-radius: 12px;
}

.balanceAmount {
  margin: 0px;
  font-weight: 600;
  font-size: 20px;
}

.description {
  @media (--viewportMedium) {
    max-width: 260px;
  }
}

.descriptionHeader {
  font-size: 16px;
  font-weight: 600;
  line-height: 18px;
  color: #1c1c1c;

  @media (--viewportMedium) {
    font-size: 20px;
    line-height: 22px;
  }
}

.descriptionBody {
  font-size: 14px;
  color: #656565;
  line-height: 18px;
  margin-top: 4px;

  @media (--viewportMedium) {
    font-size: 16px;
    line-height: 24px;
    margin-top: 8px;
  }
}

/*  */
.modalBackground {
  background-color: rgba(0, 0, 0, 0.2);
  width: 100vw;
  height: 100vh;
  z-index: 10;
  top: 0;
  left: 0;
  /* left: 50%;
  transform: translate(-50%, -50%); */
  position: absolute;
}

.centered {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.modal {
  /* width: 250px;
  height: 170px; */
  z-index: 10;
  border-radius: 16px;
  box-shadow: 0 5px 20px 0 rgba(0, 0, 0, 0.04);
  background-color: white;
}
/*  */
.payoutForm {
  /* display: flex; */
  position: absolute;

  /* align-items: center;
  justify-content: center; */
}

.payoutsHeader {
  color: #1c1c1c;
  font-size: 20px;
  font-weight: 600;
  line-height: 22px;
  margin-top: 67px;

  @media (--viewportMedium) {
    margin-top: 54px;
    font-size: 24px;
    line-height: 36px;
  }
}

.tableContainer {
  width: 100%;
  font-size: 12px;
  line-height: 22px;
  color: #656565;
  margin-top: 32px;

  @media (--viewportMedium) {
    font-size: 18px;
    line-height: 28px;
  }
}

.tableHeader {
  font-size: 12px;
  line-height: 24px;

  @media (--viewportMedium) {
    font-size: 16px;
    line-height: unset;
  }
}

.cell {
  text-transform: uppercase;
  color: #959595;
  background: #f3f3f3;
  padding: 8px 0px;

  @media (--viewportMedium) {
    background: unset;
    padding: unset;
  }
}
.cell:first-child {
  border-top-left-radius: 8px;
  border-bottom-left-radius: 8px;

  @media (--viewportMedium) {
    border-radius: unset;
  }
}
.cell:last-child {
  border-top-right-radius: 8px;
  border-bottom-right-radius: 8px;

  @media (--viewportMedium) {
    border-radius: unset;
  }
}

.cellData {
}

.amountHeaderCell {
  padding-left: 20px;
}

.payoutRow {
  border-bottom: 1px solid #ddd;
  line-height: 28px;
  /* padding: 20px; */
}
.payoutRow:last-child {
  border-bottom: none;
}

.valueContainer {
  padding: 14px 0 14px 20px;
  @media (--viewportMedium) {
    padding: 20px 0 20px 20px;
  }
}

.tableButtonsContainer {
  display: flex;
  justify-content: flex-end;
  margin-top: 38px;
}

.tableButton {
  margin-left: 12px;
  display: inline-flex;
  height: 28px;
  padding: 4px 12px 4px 12px;
  justify-content: center;
  align-items: center;
  border-radius: 6px;
  border: none;
  background: #f3f3f3;
  font-size: 14px;
  line-height: 20px;
  color: #1c1c1c;
  cursor: pointer;
  width: 168px;
  position: relative;
}
.tableButton:disabled {
  cursor: not-allowed;
  color: #ddd;
}

.leftTableButton {
  padding-left: 28px;
  margin-left: 0;
}
.rightTableButton {
  padding-right: 28px;
}

.iconArrowRightt {
  position: absolute;
  right: 12px;
}
.iconArrowLeft {
  position: absolute;
  left: 12px;
}

/* Payout result modal */
/*  */
.iconFailedBackgroundCircle,
.iconSuccessBackgroundCircle {
  width: 94px;
  height: 94px;
  border-radius: 50%;
  background-color: #ffdecf;
  display: flex;
  justify-content: center;
  align-items: center;
}

.iconSuccessBackgroundCircle {
  background-color: #dfefd7;
}

.payoutResultModal {
  z-index: 11;
  background-color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 348px;
  height: 296px;
  border-radius: 12px;
  position: relative;

  @media (--viewportMedium) {
    width: 400px;
  }
}

.payoutResultModalFailed {
  width: 348px;
  height: 370px;

  @media (--viewportMedium) {
    width: 400px;
  }
}

.closeIcon {
  position: absolute;
  top: 28px;
  right: 24px;
  cursor: pointer;
}

.payoutResultModalText {
  max-width: 219px;
  text-align: center;
}

.payoutResultModalFailed .payoutResultModalText {
  max-width: 292px;
}

.payoutResultModalHeader {
  font-size: 24px;
  font-weight: 600;
  color: #1c1c1c;
  line-height: 36px;
  margin-top: 20px;
}

.payoutResultModalDescription {
  font-size: 14px;
  line-height: 20px;
  margin-top: 8px;
  color: #656565;
}

.contactButton {
  margin-top: 31px;
  border-radius: 12px;
  background: rgba(28, 28, 28, 0.05);
  font-size: 18px;
  line-height: 24px;
  border: none;
  padding: 8px 16px;
  color: #1c1c1c;
  cursor: pointer;
}

.noResultsBlock {
  width: 100%;
  margin-top: 144px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.cardIcon {
  width: 24px;
  height: 24px;
}

.noResultsHeader {
  margin-top: 20px;
  color: #313131;
  font-size: 20px;
  line-height: 22px;
}

.noResultsSubtitle {
  margin-top: 4px;
  color: #959595;
  font-size: 18px;
  line-height: 28px;
}

/* Payout result modal END*/
/*  */

/* Payout create modal */
.modal {
  padding: 28px;
  width: 348px;

  @media (--viewportMedium) {
    width: 400px;
  }
}

.modalHeader {
  font-size: 24px;
  font-weight: 600;
  color: #1c1c1c;
  line-height: 36px; /* 150% */
}

.inputLabel,
.confirmationText {
  margin-top: 24px;
  font-weight: 600;
  margin-bottom: 8px;
  color: #1c1c1c;
  font-size: 16px;
  line-height: 22px;
}

.payoutModalError {
  color: #ff0000;
  font-size: 16px;
  line-height: 22px;
}

.confirmationText {
  font-size: 14px;
  line-height: 20px;
  font-weight: 400;
  margin-top: 0px;
}

.confirmAmount,
.confirmBank {
  font-weight: 600;
}

.inputContainer {
  display: flex;
  position: relative;
}

.currencyLabel {
  position: absolute;
  top: 5px;
  left: 3px;
  font-size: 16px;
}

.infoIconContainer {
  position: relative;
}

/* Tooltip text */
.tooltiptext {
  visibility: hidden;
  /* width: 120px; */
  width: 150px;
  background-color: #555;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;
  position: absolute;
  z-index: 1;
  top: 100%;
  left: -100%;
  opacity: 0;
  transition: opacity 0.3s;
  padding: 12px;
  font-size: 16px;

  @media (--viewportMedium) {
    width: 200px;
  }
}

/* Tooltip arrow */
.tooltiptext::after {
  content: '';
  position: absolute;
  bottom: 100%;
  right: 62%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: transparent transparent #555 transparent;

  @media (--viewportMedium) {
    right: 72%;
  }
}

/* Show the tooltip text when you mouse over the tooltip container */
.infoIconContainer:hover .tooltiptext {
  visibility: visible;
  opacity: 1;
}
/*  */

.infoIcon {
  margin-left: 8px;
  cursor: pointer;
}

.modalInput {
  padding: 4px 8px;
  border-radius: 6px;
  border: 1px solid #ddd;
  width: 200px;
  margin: 0px;
  font-size: 16px;
  line-height: 24px;
}

.modalInput:focus {
  border: 1px solid #ddd;
}

.amountInput {
  padding-left: 40px;
}
.amountInput::-webkit-inner-spin-button,
.amountInput::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.label {
  font-size: 16px;
  line-height: 24px;
  color: #656565;
}

.payoutModalButton,
.payoutModalButtonSubmit {
  padding: 8px 16px;
  border-radius: 12px;
  border: 1px solid rgba(28, 28, 28, 0.2);
  margin-left: 20px;
  cursor: pointer;
  height: 44px;
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;

  @media (--viewportMedium) {
    font-size: 18px;
    line-height: 24px;
  }
}

.payoutModalButtonSubmit {
  background-color: #4f56d3;
  color: white;
  border: none;
}

.payoutModalButtonSubmit:disabled {
  cursor: not-allowed;
  background-color: rgba(28, 28, 28, 0.05);
  color: rgba(28, 28, 28, 0.1);
}

.horizontalRuleModal {
  display: none;

  @media (--viewportMedium) {
    margin-top: 24px;
    height: 1px;
    border-bottom: 1px solid #e9e9e9;
  }
}

.confirmationBar {
  display: flex;
  margin-top: 24px;
}

.checkboxInput {
  width: 24px;
  height: 24px;
  margin-right: 12px;
}

.buttonsContainer {
  display: flex;
  width: 100%;
  justify-content: flex-end;
  margin-top: 44px;
}
/* Payout create modal END*/

.sidebarContainer {
  display: flex;
  flex-direction: row;
}
